import { BarChart } from 'ds4-beta';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import styled from 'styled-components';

export const TrendTypes = {
  UP: 'up',
  DOWN: 'down',
} as const;

export const TrendStatusTypes = {
  GOOD: 'good',
  BAD: 'bad',
} as const;

export type trendType = typeof TrendTypes[keyof typeof TrendTypes];
export type trendTypeStatus =
  typeof TrendStatusTypes[keyof typeof TrendStatusTypes];


const MAX_TREND_LINE_LABEL_SIZE = 20;
const BAR_CHART_HEIGHT = '300px';
const MAX_AXIS_LABEL_SIZE = 15;
const TREND_TYPES = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
};

const StyledBarChartContainer = styled.div`
  max-height: ${BAR_CHART_HEIGHT};
  height: ${BAR_CHART_HEIGHT};
`;

const transformBarChartData = data => {
  const { visualization, labels, rows } = data?.response?.[0]?.dataset || {};
  const { chart, chartType, xAxis, yAxis, trendLines, overview } =
    visualization || {};

  if (
    !chart ||
    chartType !== 'bar' ||
    isEmpty(labels) ||
    xAxis == null ||
    yAxis == null
  ) {
    return null;
  }

  const label = labels[yAxis];
  const barchartData = rows.map(row => ({
    name: row[xAxis],
    [yAxis]: row[yAxis],
  }));

  const dataKeys = [{ name: label, key: yAxis }];
  const trendData = trendLines.map(trendline => ({
    segment: [
      {
        x: trendline?.range?.start,
        y: trendline.value,
      },
      {
        x: trendline?.range?.end,
        y: trendline?.value,
      },
    ],
    label: trendline?.label,
    maxLabelSize: MAX_TREND_LINE_LABEL_SIZE,
  }));

  return {
    data: barchartData,
    dataKeys,
    ...(!isEmpty(trendLines) && { trendData }),
    ...(!isEmpty(overview) && {
      title: overview?.title,
      subTitle: `Last ${overview?.range}`,
      trendSummary: {
        descriptor: 'Average', // TODO hardcoded for now, connect with backend team to inherit
        metricValue: Math.abs(overview?.currentValues?.[yAxis]),
        trend:
          overview?.valueChange?.changeType === TREND_TYPES.INCREASE
            ? 'up' as trendType
            : 'down' as trendType,
        prefixSymbol:
          overview?.valueChange?.changeType === TREND_TYPES.INCREASE
            ? '+'
            : '-',
        value: Math.abs(overview?.valueChange?.changePercentage),
        trendStatus:
          overview?.valueChange?.changeType === TREND_TYPES.DECREASE
            ? 'bad' as trendTypeStatus
            : 'good' as trendTypeStatus,
      },
    }),
  };
};

export const BarChartView = ({ data }) => {
  const chartData = transformBarChartData(data);

  if (!chartData) return null;

  return (
    <StyledBarChartContainer data-testid='bar-chart'>
      <BarChart
        xInterval='equidistantPreserveStart'
        yInterval='equidistantPreserveStart'
        {...chartData}
        maxAxisLabelLength={MAX_AXIS_LABEL_SIZE}
      />
    </StyledBarChartContainer>
  );
};
