import { FabAIObservables } from '@copilot/mfa-communication';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { showShrunkFabAIChat } from '../../util';
import { FAB_AI_ROUTE } from '../components/chat-container/constants';

const useFabAIChatHomepage = () => {
  const [showDefaultView, setShowDefaultView] = useState(true);
  const [selectedChipQuery, setSelectedChipQuery] = useState('');
  const [isSideChat, setIsSideChat] = useState<boolean>(null);
  const [eventTriggered, setEventTriggered] = useState(false);
  const [input, setInput] = useState('');
  const [footerHeight, setFooterHeight] = useState(100);
  const footerRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const subscription = FabAIObservables.chatExpansionState$.subscribe(
      expansionState => {
        setIsSideChat(
          expansionState === FabAIObservables.ChatExpansionStateType.THIRD
        );
      }
    );

    return () => subscription?.unsubscribe();
  }, [location]);

  useEffect(() => {
    const footer = footerRef.current;
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setFooterHeight(entry.contentRect.height); // Dynamically set the footer's height
      }
    });

    if (footer) resizeObserver.observe(footer);
    return () => {
      if (footer) resizeObserver.unobserve(footer);
    };
  }, []);

  const handleFabAIExpansion = useCallback(() => {
    if (location.pathname === FAB_AI_ROUTE) {
      FabAIObservables.fullyOpenFabAIChat();
    } else if (showShrunkFabAIChat()) {
      FabAIObservables.shrinkFabAIChat();
    }
  }, [location.pathname]);

  useEffect(() => {
    handleFabAIExpansion();
  }, [location.pathname]);

  useEffect(() => {
    if (!eventTriggered) {
      setInput('');
    }
  }, [eventTriggered]);

  return {
    showDefaultView,
    selectedChipQuery,
    setShowDefaultView,
    setSelectedChipQuery,
    input,
    eventTriggered,
    setEventTriggered,
    setInput,
    footerHeight,
    footerRef,
    isSideChat
  };
};
export default useFabAIChatHomepage;
