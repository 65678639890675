import { GridCol, GridRow, theme } from 'ds4-beta';
import styled, { css } from 'styled-components';

export const ChatPageContainer = styled.div<{ isSideChat: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: calc(100vh - 48px); // -48px for header
  padding: ${({ isSideChat }) => (isSideChat ? `0 ${theme.space[4]}` : '0')};
  border-left: ${({ isSideChat }) =>
    isSideChat ? `1px solid ${theme.color.grey[100]}` : 'none'};
`;
export const StyledLandingPageContainer = styled.div<{ isSideChat: boolean }>`
  margin: 0 auto;
  max-width: 808px;
  flex: 1;
  @media (max-width: 1023px) {
    width: 90%;
  }

  ${({ isSideChat }) => !isSideChat && css`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;
export const StyledIconLandingPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: calc(368px - ${theme.space[6]}); // - space[6] to account for padding
`;
export const StyledHeaderContainer = styled.div``;
export const StyledIconContainerRow = styled.div<{ isSideChat: boolean }>`
  padding-top: ${theme.space[5]};
  display: flex;
  justify-content: ${({ isSideChat }) => (isSideChat ? 'flex-start' : 'center')};
`;
export const StyledTitleContainer = styled.div<{ isSideChat: boolean }>`
  display: flex;
  justify-content: ${({ isSideChat }) => (isSideChat ? 'flex-start' : 'center')};
  margin-top: ${theme.space[4]};
  ${theme.typography.h3};
  color: ${theme.color.grey[1000]};
`;
export const StyledSubTitleContainer = styled.div<{ isSideChat: boolean }>`
  display: flex;
  justify-content: ${({ isSideChat }) => (isSideChat ? 'flex-start' : 'center')};
  padding-top: ${theme.space[1]};
  ${theme.typography.body1.regular};
  color: ${theme.color.grey[700]};
`;
export const InitialPromptsContainer = styled(GridRow) <{ isSideChat: boolean }>`
  padding-top: ${({ isSideChat }) =>
    isSideChat ? theme.space[6] : theme.space[8]};
  gap: ${({ isSideChat }) => (isSideChat ? theme.space[2] : theme.space[4])};

  @media (max-width: 1400px) {
    ${({ isSideChat }) => !isSideChat && css`
      padding-top: ${theme.space[3]};
    `}
  }
`;

export const PredefinedChipContainer = styled(GridCol)``;
