
import { DataGrid, Modal } from 'ds4-beta';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getStartCasedString } from '../../../../util';
import { FAB_AI_ROUTE } from '../constants';
import {
  StyledAnalyticsTable,
  StyledSummary,
  TableCell
} from './style';
import {
  COLUMNS_TO_SHOW_CARD,
  getHeader,
  MAX_ROWS_LIMIT,
  MAX_ROWS_LIMIT_EXCEEDS_TEXT,
  ROWS_TO_SHOW_CARD
} from './util';

export const AnalyticsTable = ({ data, event }) => {
  const { pathname } = useLocation();
  const isSideChatView = pathname !== FAB_AI_ROUTE;
  const [isExpanded, setIsExpanded] = useState(false);
  const dataset = data?.response?.[0]?.dataset;
  const analysis = data?.response?.[0]?.analysis;
  const cols = dataset?.columns;
  const rows: Array<Record<string, string>> = dataset?.rows ?? [];

  useEffect(() => {
    if (pathname === FAB_AI_ROUTE) {
      setIsExpanded(false);
    }
  }, [pathname]);

  if (!cols) {
    return null;
  }

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const determineCardOrGrid = (cols, rows) => {
    return (
      cols.length > COLUMNS_TO_SHOW_CARD ||
      (cols.length <= COLUMNS_TO_SHOW_CARD && rows > ROWS_TO_SHOW_CARD)
    );
  };

  const renderDataGrid = ({ sideView, modal }) => {
    const showCard = determineCardOrGrid(cols, rows.length);
    return (
      <DataGrid
        key={sideView ? 'card' : 'datagrid'}
        columns={cols.map(col => ({
          id: col,
          label: getStartCasedString(col),
          render: (data: string | Array<string>) => {
            return <TableCell>{data}</TableCell>;
          },
        }))}
        data={rows.slice(0, MAX_ROWS_LIMIT)}
        isLoading={false}
        headerProps={{
          heading: getHeader(event),
          subTitle:
            rows.length < MAX_ROWS_LIMIT
              ? `Showing ${rows.length} rows`
              : MAX_ROWS_LIMIT_EXCEEDS_TEXT,
        }}
        {...(sideView &&
          !modal &&
          showCard && {
          iconProps: { icon: 'AIExpand', onClick: handleExpandClick },
        })}
        showGrid={!sideView || modal || !showCard}
      />
    );
  };

  return (
    <StyledAnalyticsTable>
      {analysis?.summary?.length > 0 && (
        <StyledSummary>{analysis?.summary}</StyledSummary>
      )}
      {renderDataGrid({ sideView: isSideChatView, modal: false })}
      {isExpanded && isSideChatView && (
        <Modal
          size='large'
          isVisible={isExpanded}
          mode='ai'
          onClose={handleExpandClick}
          dataTestid='analytics-datagrid-modal'
        >
          {renderDataGrid({ sideView: isSideChatView, modal: true })}
        </Modal>
      )}
    </StyledAnalyticsTable>
  );
};
