import { Nudge } from 'ds4-beta';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { HandleFollowUpClickProps, QuickReplyType } from '../types';

const QuickReplySection = ({
  quickReplies,
  onQuickReplyClick,
}: {
  quickReplies: QuickReplyType[];
  onQuickReplyClick: HandleFollowUpClickProps;
}) => {
  if (isEmpty(quickReplies)) {
    return null;
  }
  return (
    <>
      {quickReplies.map(prompt => {
        const key = prompt.attributes?.promptId;
        const promptText = prompt.attributes?.promptText;
        const ctaLabel = prompt.ctaLabel;
        console.log('prompt', prompt)

        return (
          <Nudge
            key={key}
            text={ctaLabel}
            onClick={() => {
              onQuickReplyClick({
                ctaLabel,
                promptText,
              });
            }}
          />
        );
      })}
    </>
  );
};

export default QuickReplySection;
