import { Box, Icon, theme } from 'ds4-beta';
import React from 'react';
import { AI_ICON_SIZE, SUPPORTED_EVENTS } from './constants';
import { useChatStreaming } from './hook';
import { StyledChatStreamContainer, StyledIcon } from './style';
import { MessageContent } from './types';

const ChatStreaming = ({
  setLoading,
  content,
  loading,
  isSideChat,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  content: MessageContent[];
  loading: boolean;
  isSideChat?: boolean;
}) => {
  const { isLastAiMessage, renderMessages } = useChatStreaming({
    content,
    setLoading,
  });

  return (
    <StyledChatStreamContainer
      isLastAiMessage={isLastAiMessage}
    >
      <Box width={theme.space[5]}>
        {!loading && content[0]?.event !== SUPPORTED_EVENTS.EXCEPTION && (
          <StyledIcon>
            <Icon
              iconName='CommerceGPT'
              size={AI_ICON_SIZE}
              variant={'fill'}
              dataTestid='commerceGPT-icon'
            />
          </StyledIcon>
        )}
      </Box>
      <Box
        width={isSideChat ? '300px' : 'auto'}
        flex={{ flexGrow: 1, flexDirection: 'column' }}
        dataTestid='ai-response-stream-container'
        gap={4}
      >
        {renderMessages}
      </Box>
    </StyledChatStreamContainer>
  );
};

export default ChatStreaming;
