import { FabAIObservables } from '@copilot/mfa-communication';
import { Nudge } from 'ds4-beta';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DOMAIN_APP_DEFAULT_ROUTEMAP } from '../../event-views/util';
import { HandleFollowUpClickProps, UIActionType } from '../types';

const UIActionSection = ({
  uiActionCTAs,
  onUIActionClick,
}: {
  uiActionCTAs: UIActionType[];
  onUIActionClick: HandleFollowUpClickProps;
}) => {
  const navigate = useNavigate();

  const transformedActionCTAs = useMemo(() => {
    if (isEmpty(uiActionCTAs)) {
      return [];
    }
    return uiActionCTAs.map(item => ({
      label: item.ctaLabel,
      actions: item.actions,
      suggestionEventId: item.suggestionEventId
    }));
  }, [uiActionCTAs]);

  const handleActionClick = action => {
    const domain = action?.actions[0]?.routeDetails?.domain;
    if (!isEmpty(DOMAIN_APP_DEFAULT_ROUTEMAP[domain])) {
      navigate(DOMAIN_APP_DEFAULT_ROUTEMAP[domain]);
      FabAIObservables.RxJsService.publishDataFromChatbot({
        data: action.actions,
        suggestionEventId: action.suggestionEventId
      });
    }

    onUIActionClick({
      ctaLabel: action.label,
      promptText: action.label,
      shouldTriggerResponse: false
    });
  };

  if (isEmpty(transformedActionCTAs)) return null;

  return (
    <>
      {transformedActionCTAs.map(action => (
        <Nudge
          key={`ui-action-${action.label}`}
          text={action.label}
          onClick={() => {
            handleActionClick(action);
          }}
        />
      ))}
    </>
  );
};

export default UIActionSection;
